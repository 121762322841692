import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { MinhaRede } from '../pages/rede/MinhaRede'
import { MeusIndicados } from '../pages/rede/MeusIndicados'
import { Unilevel } from '../pages/rede/Unilevel'
import { Planos } from '../pages/assinaturas/Planos'
import { MinhaAssinatura } from '../pages/assinaturas/MinhaAssinatura'
import { Extrato } from '../pages/financeiro/Extrato'
import { MinhaCarteira } from '../pages/financeiro/MinhaCarteira'
import { AtivarCarteira } from '../pages/financeiro/AtivarCarteira'
import { MeusSaques } from '../pages/saques/MeusSaques'
import { Rendimentos } from '../pages/saques/Rendimentos'
import { Bonificacoes } from '../pages/saques/Bonificacoes'
import { Perfil } from '../pages/perfil/Perfil'

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper />} />
                
                {/* Rotas Rede */}
                <Route path="rede" element={<MinhaRede />} />
                <Route path="meus-indicados" element={<MeusIndicados />} />
                <Route path="unilevel" element={<Unilevel />} />

                {/* Rotas Assinaturas */}
                <Route path="planos" element={<Planos />} />
                <Route path="minha-assinatura" element={<MinhaAssinatura />} />

                {/* Rotas Financeiro */}
                <Route path="extrato" element={<Extrato />} />
                <Route path="minha-carteira" element={<MinhaCarteira />} />
                <Route path="ativar_carteira/:codigo" element={<AtivarCarteira />} />

                {/* Rotas Saque */}

                <Route path="meus-saques" element={<MeusSaques />} />
                <Route path="saques/rendimentos" element={<Rendimentos />} />
                <Route path="saques/bonificacoes" element={<Bonificacoes />} />

                {/* Rota Perfil */}
                <Route path="perfil" element={<Perfil />} />



                {/* <Route path='builder' element={<BuilderPageWrapper />} />
                <Route path='menu-test' element={<MenuTestPage />} /> */}

                {/* Lazy Modules */}
                <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
