import React from 'react'
import InputRadio from './InputRadio'
import InputCheckbox from './InputCheckbox'
import InputSelect from './InputSelect'
import InputTextarea from './InputTextarea'
import InputText from './InputText'
import InputFile from './InputFile'
import Element from './Element'
import InputCheckboxAccept from './InputCheckboxAccept'

const InputForm = (props) => {
  return (
    <>
      {props.type === 'file' ? (
        <InputFile {...props} />
      ) : props.type === 'radio' ? (
        <InputRadio {...props} />
      ) : props.type === 'checkbox' ? (
        <InputCheckbox {...props} />
      ) : props.type === 'checkbox-accept' ? (
        <InputCheckboxAccept {...props} />
      ) : props.type === 'select' ? (
        <InputSelect {...props} />
      ) : props.type === 'textarea' ? (
        <InputTextarea {...props} />
      ) : props.type === "element" ? (
        <Element {...props} />
      ) : (
        <InputText {...props} />
      )}
    </>
  )
}

export default InputForm
