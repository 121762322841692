import React, { useEffect } from 'react'
import { idGenerator } from '../../utils/idGenerator'
import { Input, InputLabel } from '@material-ui/core';

const InputCheckboxAccept = (props) => {
  const {
    name = '',
    value = null,
    label = null,
    containerAttributes = {},
    labelAttributes = {},
    actionType = null
  } = props;

  var data = {
    className: 'form-check-input',
    name: name,
    type: 'checkbox',
    value: value,
    ...props,
  }

  if (!data.errors.valido) {
    data.className += ' border-danger'
  }

  const container = {
    className: 'form-check form-check-custom form-check-solid',
    ...containerAttributes
  }

  const labelDt = {
    className: 'form-check-label',
    htmlFor: data.id,
    ...labelAttributes
  }

  delete data.setValue
  delete data.labelAttributes
  delete data.containerAttributes
  delete data.validation
  delete data.mask

  return (
    <div {...container}>
      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
      <label className="form-check-label" for="flexCheckDefault">
        {label} {actionType != null &&
          <a target="_blank" href="https://painel.abastecebrasil.com/storage/backoffice/contrato_abastece.pdf">Termos de uso</a>
        }
      </label>
    </div>
  )
}

export default InputCheckboxAccept
